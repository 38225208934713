import React from "react";
import { Divider } from "antd";
import { useSelector } from "react-redux";
import { AppState } from "store";
import classes from "./headerBar.module.scss";
import { NotificationDropDown } from "components/contentcomponents/notification";
import { ActionsDropdown } from "components/contentcomponents/action/actionDropdown/actionDropdown";
import { useCompanyType } from "controller";
import DownloadProgress from "components/utilitycomponents/excelExport/downloadProgress";
import ExcelExportHandler from "components/utilitycomponents/excelExport/ExcelExport";
import SupportDropDown from "components/contentcomponents/support/supportDropDown";
import AccountDropDown from "components/contentcomponents/account/accountDropDown";
import { PageTitle } from "components/utilitycomponents";

const HeaderBar = () => {
    const user = useSelector((state: AppState) => state.oidc.user);
    const isGuest: boolean = user.profile["ll-Usertype"] === "Guest";

    const { isDefaultCompany } = useCompanyType();
    const companyActionAccess =
        (user.profile["ll-enabledCompanyModule-Action"] === "true" && !isDefaultCompany) ||
        isDefaultCompany;

    return (
        <div className={classes.mainHeaderContainer}>
            <div>
                <PageTitle />
            </div>

            <div className={classes.columnContainer}>
                <div className={classes.downloadContainer}>
                    <DownloadProgress className={classes.downloadProgressElement} />
                </div>
                <div className={classes.rightSide}>
                    {" "}
                    <div className={classes.devider}>
                        <Divider type="vertical" className={classes.icon} />
                    </div>
                    <div className={classes.notificationContainer}>
                        <SupportDropDown />
                    </div>
                    {!isGuest && companyActionAccess && (
                        <div className={classes.notificationContainer}>
                            <ActionsDropdown />
                        </div>
                    )}
                    {!isGuest && (
                        <div className={classes.notificationContainer}>
                            <NotificationDropDown />
                        </div>
                    )}
                    <div className={classes.notificationContainer}>
                        <AccountDropDown />
                    </div>
                </div>
                <ExcelExportHandler />
            </div>
        </div>
    );
};

export { HeaderBar };
