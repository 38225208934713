import React, { useEffect, useState } from "react";
import i18n from "i18next";
import axios from "axios";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Backend from "i18next-locize-backend";
//@ts-ignore
import { locizePlugin } from "locize";
import { User } from "oidc-client";
import userManager from "userManager";
import { Layout, ConfigProvider } from "antd";
import "moment/locale/da";
import "moment/locale/de";
import "moment/locale/en-gb";
import "moment/locale/es";
import "moment/locale/fr";
import Routes from "./routes/routes";
import { useCompanyType, usePrevious } from "controller";
import { AppState } from "store";
import {
    getBackendDomainUrl,
    getDomainUrl,
    getGuestTokenFromPathName,
    isGuestAuthorizedPath,
    momentLocale,
} from "utilities";
import Config from "../../../config";
import ApiErrorNotification from "components/utilitycomponents/apiErrorNotification";
import Login from "./login";
import classes from "./styles/main.module.scss";
import UnAuthenticatedRoutes from "./routes/unAuthenticatedRoutes";
import { getAntLocale, setLanguage } from "utilities";
import { MainMenu } from "components/alwaysOnComponents/mainMenu/mainMenu";
import { HeaderBar } from "components/alwaysOnComponents/header/headerBar";
import moment from "moment-timezone";
import { SupplierHeaderBar } from "components/alwaysOnComponents/header/supplierHeaderBar";
import SupplierRoutes from "./routes/supplierRoutes";
import OnboardingExistingUsers from "components/contentcomponents/user/userRegistration/onBoardComponents/onboardingExistingUsers";
import { antdThemeConfig } from "./antdConfig/antdThemeConfig";
import { AlignLeft01, AlignRight01 } from "components/utilitycomponents";

const { Header, Content, Sider } = Layout;

const Main = () => {
    const { location } = useHistory();
    const user = useSelector((state: AppState) => state.oidc.user);
    const preLanguage = usePrevious(user ? user.profile["ll-Locale"] : null);
    const [collapsed, setCollaped] = useState(false);
    const { isDefaultCompany } = useCompanyType();

    useEffect(() => {
        if (location.pathname.includes("SupplierProfile")) {
            setCollaped(true);
        }
    }, [location.pathname]);

    useEffect(() => {
        if (user && user.profile["ll-Usertype"] === "Guest") {
            setCollaped(true);
        }
    }, [user]);

    if (!user || user.expired) {
        if (isUnAuthenticatedPath(location.pathname) === true) {
            initI18N(true, "");
            return <UnAuthenticatedRoutes />;
        } else {
            return <Login />;
        }
    } else if (user.profile["ll-Usertype"] === Config.guestUser) {
        handleGuestUser(user, location.pathname);
    }

    axios.defaults.headers.common["Authorization"] = "Bearer " + user.access_token;

    if (preLanguage !== user.profile["ll-Locale"]) {
        initI18N(false, user.profile["ll-Locale"]);
        moment.locale(momentLocale(user.profile["ll-Locale"]));
        moment.tz.setDefault("Etc/UTC");
    }

    if (window.location.href.indexOf("beta-app") !== -1) {
        window.location.href = window.location.href.replace("beta-app", "");
    }

    return (
        <ConfigProvider locale={getAntLocale(user.profile["ll-Locale"])} theme={antdThemeConfig}>
            <div className="App">
                <Layout className={classes.layoutStyle}>
                    <ApiErrorNotification />
                    {isDefaultCompany && (
                        <Sider
                            style={{ borderRight: "1px solid #4754671b" }}
                            trigger={
                                collapsed ? (
                                    <div className={classes.triggerStyle}>
                                        <AlignRight01 className={classes.triggerIcon} />
                                    </div>
                                ) : (
                                    <div className={classes.expandMenuTrigger}>
                                        <AlignLeft01 className={classes.triggerIcon} />
                                    </div>
                                )
                            }
                            width={250}
                            id="sider"
                            className={classes.sider}
                            collapsedWidth={80}
                            collapsible={true}
                            collapsed={collapsed}
                            onCollapse={() => setCollaped(!collapsed)}>
                            <MainMenu collapsed={collapsed} />
                        </Sider>
                    )}

                    <Layout>
                        <Header className={classes.header} id="headerSection">
                            {isDefaultCompany && <HeaderBar />}
                            {!isDefaultCompany && <SupplierHeaderBar />}
                        </Header>
                        <Content className={classes.contentContainer} id="contentSection">
                            {isDefaultCompany && <Routes />}
                            {!isDefaultCompany && <SupplierRoutes />}
                        </Content>
                    </Layout>
                </Layout>
            </div>
            <OnboardingExistingUsers />
        </ConfigProvider>
    );
};

export default Main;

async function initI18N(unAuthenticatedRoute: boolean, profileLocale: string) {
    const domain = getBackendDomainUrl();
    await i18n
        .use(Backend)
        .use(locizePlugin)
        .init({
            react: {
                bindI18n: "languageChanged editorSaved",
            },
            debug: false,
            lng: unAuthenticatedRoute ? "en-GB" : setLanguage(profileLocale),
            fallbackLng: "en-GB",
            defaultNS: "common",
            backend: {
                projectId: Config.locizeProjectId,
            },
        })
        .catch(() =>
            i18n.use(Backend).init({
                debug: false,
                lng: unAuthenticatedRoute ? "en-GB" : profileLocale,
                fallbackLng: "en-GB",
                load: "currentOnly",
                ns: "translation",
                interpolation: {
                    escapeValue: false, // not needed for react as it escapes by default
                },
                backend: {
                    crossDomain: true,
                    allowMultiLoading: false,
                    loadPath: `${domain}/v1/File/GetLanguageRessourceFile?nameSpace={{ns}}&locale={{lng}}`,
                },
            })
        );
}

function isUnAuthenticatedPath(pathname: string) {
    if (
        Config.unAuthenticatedRoutes.filter(x => pathname.toLowerCase().includes(x.toLowerCase()))
            .length > 0
    ) {
        return true;
    } else {
        return false;
    }
}

function handleGuestUser(user: User, pathName: string) {
    let urlGuestToken = getGuestTokenFromPathName(pathName);

    if (!isGuestAuthorizedPath(pathName) || !urlGuestToken) {
        userManager.signoutRedirect({
            id_token_hint: user.id_token,
        });
        userManager.removeUser();
    } else if (urlGuestToken !== user.profile["ll-GuestToken"]) {
        userManager.signoutRedirect({
            id_token_hint: user.id_token,
            extraQueryParams: {
                returnUrl: getDomainUrl() + pathName,
            },
        });
        userManager.removeUser();
    }
}
