import { Reducer, Action } from "redux";
import React from "react";

export interface KeysState {
    activeKey: string;
    selectedKey: any[];
    expandedKeys: React.Key[];
}

const initialState: KeysState = {
    activeKey: localStorage.getItem("activeKey") || "",
    selectedKey: JSON.parse(localStorage.getItem("selectedKey") || "[]"),
    expandedKeys: JSON.parse(localStorage.getItem("expandedKeys") || "[]"),
};

const SET_ACTIVE_KEY = "SET_ACTIVE_KEY";
const SET_SELECTED_KEY = "SET_SELECTED_KEY";
const SET_EXPANDED_KEYS = "SET_EXPANDED_KEYS";
const RESET_EXPANDED_KEYS = "RESET_EXPANDED_KEYS";

interface SetActiveKeyAction extends Action<typeof SET_ACTIVE_KEY> {
    payload: string;
}
interface SetSelectedKeyAction extends Action<typeof SET_SELECTED_KEY> {
    payload: {
        selectedKeys: any[];
        parentKey: string;
    };
}
interface SetExpandedKeysAction extends Action<typeof SET_EXPANDED_KEYS> {
    payload: React.Key[];
}
interface ResetExpandedKeysAction extends Action<typeof RESET_EXPANDED_KEYS> {}

export const setActiveKeyState = (activeKey: string): SetActiveKeyAction => ({
    type: SET_ACTIVE_KEY,
    payload: activeKey,
});

export const setSelectedKeyState = ({
    selectedKeys,
    parentKey,
}: {
    selectedKeys: any[];
    parentKey: string;
}): SetSelectedKeyAction => ({
    type: SET_SELECTED_KEY,
    payload: { selectedKeys, parentKey },
});

export const setExpandedKeys = (keys: React.Key[]): SetExpandedKeysAction => ({
    type: SET_EXPANDED_KEYS,
    payload: keys,
});

export const resetExpandedKeys = (): ResetExpandedKeysAction => ({
    type: RESET_EXPANDED_KEYS,
});

type KeyActions =
    | SetActiveKeyAction
    | SetSelectedKeyAction
    | SetExpandedKeysAction
    | ResetExpandedKeysAction;

const mainMenuReducer: Reducer<KeysState, KeyActions> = (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTED_KEY:
            const { selectedKeys, parentKey } = action.payload;
            localStorage.setItem("selectedKey", JSON.stringify(selectedKeys));
            localStorage.setItem("activeKey", parentKey);
            return { ...state, selectedKey: selectedKeys, activeKey: parentKey };

        case SET_EXPANDED_KEYS:
            return { ...state, expandedKeys: action.payload };

        case RESET_EXPANDED_KEYS:
            localStorage.removeItem("expandedKeys");
            return { ...state, expandedKeys: [] };

        default:
            return state;
    }
};

export default mainMenuReducer;
