import { useTranslation } from "react-i18next";
import { Menu, TreeDataNode } from "antd";
import React from "react";
import classes from "./mainMenu.module.scss";
import { setSelectedKeyState } from "store/mainMenu/mainMenuSlice";
import { AppState } from "store";
import {
    AlertOctagon,
    ArrowRight,
    BarChart07,
    ChevronDown,
    ClipboardCheck,
    Globe01,
    Star01,
    Users01,
} from "components/utilitycomponents";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
interface MenuItem {
    key: string;
    title: any;
    icon?: React.ReactNode;
    url?: string;
    parentKey?: string;
    children?: MenuItem[];
}
export const useMenuTreeData = (
    type?: "supplier" | "contacts" | "compliance" | "reviews" | "ncr" | "actions" | "analytics",
    collapsed?: boolean,
    isDefaultCompany?: boolean,
    userType?: string,
    ncrPbiReports?: any
): any[] => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const expandedKeys = useSelector((state: AppState) => state.mainMenu.expandedKeys);
    const activeKey = useSelector((state: AppState) => state.mainMenu.activeKey);
    const titleClass = collapsed ? classes.collapsedTitle : classes.expandedTitle;
    const activeTitleClass = collapsed ? "" : classes.activeExpandedTitle;
    const handleSelect = (selectedKeys: React.Key[], parentKey: string) => {
        dispatch(setSelectedKeyState({ selectedKeys, parentKey }));
    };

    const supplierMenuItems: MenuItem[] = [
        {
            title: (
                <div className={`${activeKey === "supplier" ? activeTitleClass : titleClass}`}>
                    <div>
                        <Globe01 style={{ fontSize: 24, marginRight: 16 }} />
                        {isDefaultCompany ? t("topbar_Suppliers") : t("topbar_Customers")}
                    </div>
                    <div>
                        {expandedKeys.includes("supplier") ? (
                            <ChevronDown />
                        ) : (
                            <ChevronDown style={{ rotate: "180deg" }} />
                        )}
                    </div>
                </div>
            ),
            icon: (
                <div className={`${activeKey === "supplier" ? classes.activeCollapsedTitle : ""}`}>
                    <Globe01 style={{ fontSize: 24 }} />{" "}
                </div>
            ),
            key: "supplier",
            children: [
                {
                    parentKey: "supplier",
                    key: "supplier:0",
                    title: t("mainMenu_PartnerOverview", {
                        partnerType: isDefaultCompany
                            ? t("topbar_Suppliers")
                            : t("topbar_Customers"),
                    }),
                    url: "/Partners",
                },
                {
                    parentKey: "supplier",
                    key: "supplier:1",
                    title: t("topbar_SupplierMasterdata", "Master Data Overview"),
                    url: "/Partners/Masterdata",
                },
            ],
        },
    ];

    const contactsMenuItems: any[] = [
        {
            title: (
                <div className={`${activeKey === "contacts" ? activeTitleClass : titleClass}`}>
                    <div>
                        <Users01 style={{ fontSize: 24, marginRight: 16 }} />
                        {t("topbar_Contacts")}
                    </div>
                    <div>
                        {expandedKeys.includes("contacts") ? (
                            <ChevronDown />
                        ) : (
                            <ChevronDown style={{ rotate: "180deg" }} />
                        )}
                    </div>
                </div>
            ),
            icon: (
                <div className={`${activeKey === "contacts" ? classes.activeCollapsedTitle : ""}`}>
                    <Users01 style={{ fontSize: 24 }} />
                </div>
            ),
            key: "contacts",

            children: [
                {
                    key: "contacts:0",
                    title: t("mainMenu_ContactsOverview"),
                    url: "/Contacts",
                    parentKey: "contacts",
                },
                {
                    key: "contacts:1",
                    title: t("topbar_ContactGroupOverview", "Group Overview"),
                    url: "/Contacts/ContactGroup",
                    parentKey: "contacts",
                },
            ],
        },
    ];
    const reviewsMenuItems: any[] = [
        {
            title: (
                <div className={`${activeKey === "reviews" ? activeTitleClass : titleClass}`}>
                    <div>
                        <Star01
                            className={classes.icon}
                            style={{ fontSize: 24, marginRight: 16 }}
                        />
                        {t("topbar_Reviews")}
                    </div>

                    <div>
                        {expandedKeys.includes("reviews") ? (
                            <ChevronDown />
                        ) : (
                            <ChevronDown style={{ rotate: "180deg" }} />
                        )}
                    </div>
                </div>
            ),
            key: "reviews",
            icon: (
                <div className={`${activeKey === "reviews" ? classes.activeCollapsedTitle : ""}`}>
                    <Star01 className={classes.icon} style={{ fontSize: 24 }} />
                </div>
            ),

            children:
                userType !== "lightuser"
                    ? [
                          {
                              parentKey: "reviews",
                              key: "reviews:1",
                              title: t("mainMenu_ReviewOverview"),
                              url: "/Reviews",
                          },
                          {
                              parentKey: "reviews",
                              key: "reviews:2",
                              title: t("topbar_SurveyOverview"),
                              url: "/Reviews/SurveyOverview",
                          },
                          {
                              parentKey: "reviews",
                              key: "reviews:3",
                              title: t("topbar_SurveyRequests"),
                              url: "/SurveyRequests",
                          },
                          {
                              parentKey: "reviews",
                              key: "reviews:4",
                              title: t("topbar_NonResponseOverview"),
                              url: "/Reviews/NonResponseOverview",
                          },
                          {
                              parentKey: "reviews",
                              key: "reviews:5",
                              title: t("topbar_AutoSurveyOverview"),
                              url: "/Reviews/AutomatedSurveyOverview",
                          },
                      ]
                    : [
                          {
                              parentKey: "reviews",
                              key: "reviews:1",
                              title: t("topbar_ReviewOverview"),
                              url: "/Reviews",
                          },
                      ],
        },
    ];

    const ncrMenuItems: any[] = [
        {
            title: (
                <div className={`${activeKey === "ncr" ? activeTitleClass : titleClass}`}>
                    <div>
                        <AlertOctagon style={{ fontSize: 24, marginRight: 16 }} />
                        {t("topbar_Ncr")}
                    </div>
                    <div>
                        {expandedKeys.includes("ncr") ? (
                            <ChevronDown />
                        ) : (
                            <ChevronDown style={{ rotate: "180deg" }} />
                        )}
                    </div>
                </div>
            ),
            icon: (
                <div className={`${activeKey === "ncr" ? classes.activeCollapsedTitle : ""}`}>
                    <AlertOctagon style={{ fontSize: 24 }} />
                </div>
            ),
            key: "ncr",

            children: [
                {
                    parentKey: "ncr",
                    key: "ncr:0",
                    title: t("mainMenu_NCROverview"),
                    url: "/Ncrs",
                },
                ncrPbiReports?.length > 0
                    ? {
                          parentKey: "ncr",

                          key: "ncr:1",
                          title: "Portfolio",
                          url: "/Ncrs/Portfolio",
                      }
                    : null,
            ].filter(Boolean),
        },
    ];
    const complianceMenuItems: any[] = [
        {
            title: (
                <div className={`${activeKey === "compliance" ? activeTitleClass : titleClass}`}>
                    <div>
                        <ClipboardCheck style={{ fontSize: 24, marginRight: 16 }} />
                        {t("topbar_Compliance")}
                    </div>
                    <div>
                        {expandedKeys.includes("compliance") ? (
                            <ChevronDown />
                        ) : (
                            <ChevronDown style={{ rotate: "180deg" }} />
                        )}
                    </div>
                </div>
            ),
            icon: (
                <div
                    className={`${activeKey === "compliance" ? classes.activeCollapsedTitle : ""}`}>
                    <ClipboardCheck style={{ fontSize: 24 }} />
                </div>
            ),
            key: "compliance",

            children: [
                {
                    parentKey: "compliance",
                    key: "compliance:0",
                    title: t("mainMenu_ComplianceOverview"),
                    url: "/Compliance",
                },
                ...(userType === "administrator" || userType === "user"
                    ? [
                          {
                              parentKey: "compliance",
                              key: "compliance:1",
                              title: t("topbar_ComplianceMatrix", "Matrix Overview"),
                              url: "/Compliance/MatrixOverview",
                          },
                          {
                              parentKey: "compliance",
                              key: "compliance:2",
                              title: t("topbar_ComplianceRequests", "Requests"),
                              url: "/Compliance/Requests",
                          },
                      ]
                    : []),
            ],
        },
    ];

    const actionsMenuItems: any[] = [
        {
            title: (
                <div className={`${activeKey === "actions" ? activeTitleClass : titleClass}`}>
                    <div>
                        <ArrowRight style={{ fontSize: 24, marginRight: 16 }} />
                        {t("actions_Actions")}
                    </div>
                    <div>
                        {expandedKeys.includes("actions") ? (
                            <ChevronDown />
                        ) : (
                            <ChevronDown style={{ rotate: "180deg" }} />
                        )}
                    </div>
                </div>
            ),
            icon: (
                <div className={`${activeKey === "actions" ? classes.activeCollapsedTitle : ""}`}>
                    <ArrowRight style={{ fontSize: 24 }} />
                </div>
            ),
            key: "actions",

            children: [
                {
                    parentKey: "actions",
                    key: "actions:0",
                    id: "actions:0",
                    title: t("mainMenu_ActionsOverview"),
                    url: "/Actions",
                    category: "",
                    hasDivider: false,
                },
                {
                    parentKey: "actions",
                    key: "actions:1",
                    id: "actions:1",
                    title: t("developmentPlans_Overview"),
                    url: "/DevelopmentPlans",
                    category: "",
                    hasDivider: false,
                },
            ],
        },
    ];

    const sharedAnalyticsMenuItems: any[] = [
        {
            title: (
                <div
                    className={`${
                        activeKey === "partnerAnalytics" ? activeTitleClass : titleClass
                    }`}>
                    <div>
                        <BarChart07 style={{ fontSize: 24, marginRight: 16 }} />
                        {t("analytics_Shared_MenuTitle")}
                    </div>
                    <div>
                        {expandedKeys.includes("partnerAnalytics") ? (
                            <ChevronDown />
                        ) : (
                            <ChevronDown style={{ rotate: "180deg" }} />
                        )}
                    </div>
                </div>
            ),
            icon: (
                <div
                    className={`${
                        activeKey === "partnerAnalytics" ? classes.activeCollapsedTitle : ""
                    }`}>
                    <BarChart07 style={{ fontSize: 24 }} />
                </div>
            ),
            key: "partnerAnalytics",

            children: [
                {
                    parentKey: "partnerAnalytics",
                    key: "sharedAnalytics:0",
                    id: "sharedAnalytics:0",
                    title: "Shared Analytics",
                    url: "/partnerAnalytics",
                    hasDivider: false,
                },
            ],
        },
    ];

    const renderTreeData = (menuItems: MenuItem[]): TreeDataNode[] => {
        return menuItems.map(item => ({
            key: item.key,
            title: collapsed ? null : item.title,
            icon: collapsed ? item.icon : null,
            parentKey: item.parentKey,
            children: item.children ? renderTreeData(item.children) : undefined,
            url: item.url,
        }));
    };

    const renderMenuItems = (menuItems: MenuItem[]): JSX.Element[] => {
        return menuItems.map(item => (
            <Menu.SubMenu key={item.key} title={item.icon} className={classes.subMenu}>
                {item.children?.map(child => (
                    <Menu.Item
                        key={child.key}
                        onClick={() => handleSelect([child.key], child.parentKey || "")}>
                        <Link to={child.url || "#"}>{child.title}</Link>
                    </Menu.Item>
                ))}
            </Menu.SubMenu>
        ));
    };
    switch (type) {
        case "supplier":
            return collapsed
                ? renderMenuItems(supplierMenuItems)
                : renderTreeData(supplierMenuItems);
        case "contacts":
            return collapsed
                ? renderMenuItems(contactsMenuItems)
                : renderTreeData(contactsMenuItems);
        case "compliance":
            return collapsed
                ? renderMenuItems(complianceMenuItems)
                : renderTreeData(complianceMenuItems);
        case "reviews":
            return collapsed ? renderMenuItems(reviewsMenuItems) : renderTreeData(reviewsMenuItems);
        case "ncr":
            return collapsed ? renderMenuItems(ncrMenuItems) : renderTreeData(ncrMenuItems);
        case "actions":
            return collapsed ? renderMenuItems(actionsMenuItems) : renderTreeData(actionsMenuItems);
        case "analytics":
            return collapsed
                ? renderMenuItems(sharedAnalyticsMenuItems)
                : renderTreeData(sharedAnalyticsMenuItems);
        default:
            return [];
    }
};
