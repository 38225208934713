import React from "react";
import Icon from "@ant-design/icons";
import { GetProps } from "antd";
import classNames from "classnames";
import classes from "./styles/customIcon.module.css";

type CustomIconComponentProps = GetProps<typeof Icon>;

function AlignLeft01(props: Partial<GetProps<typeof Icon>>) {
    const svg = () => (
        <svg
            className={classNames(classes.customIconDefaultStyle, props.className)}
            style={{ height: props.style?.fontSize, width: props.style?.fontSize }}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M3 3V21M21 12H7M7 12L14 19M7 12L14 5" style={{ stroke: props.style?.color }} />
        </svg>
    );

    const AlignLeft01Icon = (props: Partial<CustomIconComponentProps>) => (
        <Icon component={svg} {...props} />
    );
    return <AlignLeft01Icon {...props} />;
}

export default AlignLeft01;
