import React from "react";
import Icon from "@ant-design/icons";
import { GetProps } from "antd";
import classNames from "classnames";
import classes from "./styles/customIcon.module.css";
type CustomIconComponentProps = GetProps<typeof Icon>;

function AlignRight01(props: Partial<GetProps<typeof Icon>>) {
    const svg = () => (
        <svg
            className={classNames(classes.customIconDefaultStyle, props.className)}
            style={{ height: props.style?.fontSize, width: props.style?.fontSize }}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21 21V3M3 12H17M17 12L10 5M17 12L10 19"
                style={{ stroke: props.style?.color }}
            />
        </svg>
    );

    const AlignRight01Icon = (props: Partial<CustomIconComponentProps>) => (
        <Icon component={svg} {...props} />
    );
    return <AlignRight01Icon {...props} />;
}

export default AlignRight01;
