import { DataNode } from "antd/es/tree";
import Tree from "antd/es/tree/Tree";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setExpandedKeys, setSelectedKeyState } from "store/mainMenu/mainMenuSlice";
import { AppState } from "store";
import classes from "./treeStyle.module.scss";

interface IProps {
    treeData: DataNode[];
    onSelect: (selectedKeys: React.Key[], info: any) => void;
    collapsed?: boolean;
}

const TreeComponent: React.FC<IProps> = ({ treeData, onSelect, collapsed }) => {
    const dispatch = useDispatch();
    const expandedKeys = useSelector((state: AppState) => state.mainMenu.expandedKeys);
    const selectedKey = useSelector((state: AppState) => state.mainMenu.selectedKey);
    const activeKey = useSelector((state: AppState) => state.mainMenu.activeKey);

    const handleSelect = (selectedKeys: React.Key[], info: any) => {
        const isChild = info.node.parentKey;
        if (isChild) {
            const parentKey = info.node.parentKey;
            dispatch(setSelectedKeyState({ selectedKeys, parentKey }));
        }
        const isExpanded = expandedKeys.includes(info.node.key);
        if (isExpanded) {
            dispatch(setExpandedKeys(expandedKeys.filter(key => key !== info.node.key)));
        } else {
            dispatch(setExpandedKeys([...expandedKeys, info.node.key]));
        }
        onSelect(selectedKeys, info);
    };

    const handleExpand = (keys: React.Key[]) => {
        dispatch(setExpandedKeys(keys));
    };

    const renderTreeDataWithStyles = (nodes: DataNode[]): DataNode[] =>
        nodes.map(node => {
            const isActiveParent = activeKey === node.key && selectedKey.length > 0;
            const isActiveChild = selectedKey.includes(node.key);

            const titleClass = isActiveParent
                ? classes.activeParent
                : isActiveChild
                ? classes.activeChild
                : "";

            return {
                ...node,

                title: <span className={titleClass}>{node.title}</span>,
                children: node.children ? renderTreeDataWithStyles(node.children) : undefined,
            };
        });

    const processedTreeData = renderTreeDataWithStyles(treeData);

    return (
        <div className={classes.mainContainer}>
            <div className={collapsed ? classes.collapsedTree : classes.treeContainer}>
                <Tree
                    className="tree"
                    treeData={processedTreeData}
                    selectedKeys={selectedKey}
                    onSelect={handleSelect}
                    showIcon
                    showLine
                    switcherIcon={null}
                    expandedKeys={expandedKeys}
                    onExpand={handleExpand}
                />
            </div>
        </div>
    );
};

export default TreeComponent;
