import React from "react";
import { setSelectedKeyState, resetExpandedKeys } from "store/mainMenu/mainMenuSlice";
import { Divider, Menu, TreeProps } from "antd";
import {
    useAvailableSharedDashboards,
    useCompanyType,
    useEnabledPowerBiReportsByArea,
    useProductFeature,
    useEmbeddedReportSettings,
    useNcrFeature,
    useFilesFeature,
} from "controller";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { AppState } from "store";
import Config from "../../../config";
import { SettingFilled } from "@ant-design/icons";
import classes from "./mainMenu.module.scss";
import { AdminMenu } from "./adminMenu/adminMenu";
import { AreaType } from "models";
import { useMenuTreeData } from "./menuItems";
import TreeComponent from "components/utilitycomponents/treeComponent/treeComponent";
import { File02, Home02, Package, PieChart02 } from "components/utilitycomponents";
import logo from "assets/images/logo.svg";
import collapsedIcon from "assets/images/CollapsedIcon.svg";
const { SubMenu } = Menu;

interface IProps {
    collapsed?: boolean;
}

const MainMenu = ({ collapsed }: IProps) => {
    const activeKey = useSelector((state: AppState) => state.mainMenu.activeKey);
    const selectedKey = useSelector((state: AppState) => state.mainMenu.selectedKey);
    const reload = useSelector((state: AppState) => state.reload.reloadState);
    const { data: reports } = useEmbeddedReportSettings(reload);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { isDefaultCompany } = useCompanyType();
    const user = useSelector((state: AppState) => state.oidc.user);
    const userType: string = user.profile["ll-Usertype"];
    const { hasProductFeature } = useProductFeature();
    const { hasNcrFeature } = useNcrFeature();
    const { hasFilesFeature } = useFilesFeature();
    const hasReviewAccess = user.profile["ll-Review"] === "true" ? true : false;
    const hasAdminAccess = user.profile["ll-Admin"] === "true" ? true : false;
    const hasComplianceAccess = user.profile["ll-Compliance"] === "true" ? true : false;
    const hasSupplierAccess = user.profile["ll-Partner"] === "true" ? true : false;
    const hasNcrAccess = user.profile["ll-Ncr"] === "true" ? true : false;
    const hasFilesAccess = user.profile["ll-Files"] === "true" ? true : false;
    const hasContactAccess = user.profile["ll-Contact"] === "true" ? true : false;
    const hasKpiAccess = user.profile["ll-Kpi"] === "true" ? true : false;
    const hasReportingAccess = user.profile["ll-Reporting"] === "true";
    const hasProductAccess = user.profile["ll-Product"] === "true";
    const companyActionAccess =
        (user.profile["ll-enabledCompanyModule-Action"] === "true" && !isDefaultCompany) ||
        isDefaultCompany;
    const companyComplianceAccess =
        (user.profile["ll-enabledCompanyModule-Compliance"] === "true" && !isDefaultCompany) ||
        isDefaultCompany;
    const companyContactAccess =
        (user.profile["ll-enabledCompanyModule-Contact"] === "true" && !isDefaultCompany) ||
        isDefaultCompany;
    const companyPartnersAccess =
        (user.profile["ll-enabledCompanyModule-Supplier"] === "true" && !isDefaultCompany) ||
        isDefaultCompany;
    const companyFilesAccess =
        (user.profile["ll-enabledCompanyModule-File"] === "true" && !isDefaultCompany) ||
        isDefaultCompany;
    const companyNcrAccess =
        (user.profile["ll-enabledCompanyModule-Ncr"] === "true" && !isDefaultCompany) ||
        isDefaultCompany;
    const companyReviewAccess =
        (user.profile["ll-enabledCompanyModule-Review"] === "true" && !isDefaultCompany) ||
        isDefaultCompany;
    const companyReportingAccess =
        (user.profile["ll-enabledCompanyModule-Reporting"] === "true" && !isDefaultCompany) ||
        isDefaultCompany;
    const { data: sharedDashboards } = useAvailableSharedDashboards();
    const isGuest: boolean = user.profile["ll-Usertype"] === "Guest";
    const history = useHistory();

    const { data: ncrPbiReports } = useEnabledPowerBiReportsByArea(
        AreaType.Ncr,
        userType === Config.guestUser
    );

    const onSelect: TreeProps["onSelect"] = (selectedKeys: any, info: any) => {
        history.push(info.node?.url);
    };

    const handleOnTitleClick = (location: string, key: string) => {
        dispatch(setSelectedKeyState({ selectedKeys: [], parentKey: key }));
        dispatch(resetExpandedKeys());
        history.push(location);
    };

    const supplierTreeData = useMenuTreeData("supplier", collapsed, isDefaultCompany);
    const contactTreeData = useMenuTreeData("contacts", collapsed);
    const reviewsTreeData = useMenuTreeData("reviews", collapsed);
    const ncrTreeData = useMenuTreeData("ncr", collapsed, undefined, undefined, ncrPbiReports);
    const complianceTreeData = useMenuTreeData("compliance", collapsed, undefined, userType);
    const actionsTreeData = useMenuTreeData("actions", collapsed);
    const sharedAnalyticsTreeData = useMenuTreeData("analytics", collapsed);
    const menuItemClass = collapsed ? classes.collapsedMenuItem : classes.noRowSubMenu;
    const buttonActiveStyle = collapsed ? classes.collapsedMenuButton : classes.expandedMenuButton;
    const iconMargin = collapsed ? 0 : 16;
    return (
        <>
            {userType !== Config.guestUser ? (
                <Menu
                    id="mainMenu"
                    mode="vertical"
                    className={classes.menu}
                    selectedKeys={selectedKey}
                    activeKey={activeKey}>
                    <div className={classes.logoContainer}>
                        <img
                            src={collapsed ? collapsedIcon : logo}
                            alt="Lean Linking icon"
                            className={`${collapsed ? classes.collapsLogo : classes.companyLogo}`}
                        />
                    </div>
                    <Divider className={classes.divider} />
                    {userType !== Config.guestUser && (
                        <SubMenu
                            onTitleClick={(event: any) => handleOnTitleClick("/", "home")}
                            className={`${
                                activeKey === "home" ? buttonActiveStyle : menuItemClass
                            }`}
                            key={"home"}
                            title={
                                <div className={classes.buttonTitle}>
                                    <Link to="/">
                                        <Home02
                                            id="homeIcon"
                                            style={{ fontSize: 24, marginRight: iconMargin }}
                                        />
                                        {!collapsed && t("topbar_Home")}
                                    </Link>
                                </div>
                            }></SubMenu>
                    )}
                    {(userType === "user" || userType === "administrator") &&
                        hasSupplierAccess &&
                        companyPartnersAccess &&
                        (collapsed ? (
                            <Menu className={menuItemClass}> {supplierTreeData} </Menu>
                        ) : (
                            <TreeComponent
                                treeData={supplierTreeData}
                                onSelect={onSelect}
                                collapsed={collapsed}
                            />
                        ))}
                    {(userType === "administrator" || userType === "user") &&
                        hasContactAccess &&
                        companyContactAccess &&
                        (collapsed ? (
                            <Menu className={menuItemClass}> {contactTreeData} </Menu>
                        ) : (
                            <TreeComponent
                                treeData={contactTreeData}
                                onSelect={onSelect}
                                collapsed={collapsed}
                            />
                        ))}
                    {hasFilesFeature &&
                        (userType === "user" || userType === "administrator") &&
                        hasFilesAccess &&
                        companyFilesAccess && (
                            <SubMenu
                                className={`${
                                    activeKey === "files" ? buttonActiveStyle : menuItemClass
                                }`}
                                key={"files"}
                                onTitleClick={(event: any) => handleOnTitleClick("/Files", "files")}
                                title={
                                    <div className={classes.buttonTitle}>
                                        <Link to="#">
                                            <File02
                                                style={{ fontSize: 24, marginRight: iconMargin }}
                                            />
                                            {!collapsed && t("topbar_Files")}
                                        </Link>
                                    </div>
                                }></SubMenu>
                        )}
                    {hasReviewAccess &&
                        companyReviewAccess &&
                        (collapsed ? (
                            <Menu className={menuItemClass}>{reviewsTreeData}</Menu>
                        ) : (
                            <TreeComponent
                                treeData={reviewsTreeData}
                                onSelect={onSelect}
                                collapsed={collapsed}
                            />
                        ))}
                    {hasNcrFeature &&
                        hasNcrAccess &&
                        companyNcrAccess &&
                        (collapsed ? (
                            <Menu className={menuItemClass}>{ncrTreeData}</Menu>
                        ) : (
                            <TreeComponent
                                treeData={ncrTreeData}
                                onSelect={onSelect}
                                collapsed={collapsed}
                            />
                        ))}
                    {hasComplianceAccess &&
                        companyComplianceAccess &&
                        (collapsed ? (
                            <Menu className={menuItemClass}>{complianceTreeData}</Menu>
                        ) : (
                            <TreeComponent
                                treeData={complianceTreeData}
                                onSelect={onSelect}
                                collapsed={collapsed}
                            />
                        ))}
                    {userType !== "lightuser" && hasProductFeature && hasProductAccess && (
                        <SubMenu
                            onTitleClick={(event: any) =>
                                handleOnTitleClick("/Products", "products")
                            }
                            className={`${
                                activeKey === "products" ? buttonActiveStyle : menuItemClass
                            }`}
                            key={"products"}
                            title={
                                <div className={classes.buttonTitle}>
                                    <Link to="#">
                                        <Package
                                            style={{ fontSize: 24, marginRight: iconMargin }}
                                        />
                                        {!collapsed && t("product_Products")}
                                    </Link>
                                </div>
                            }></SubMenu>
                    )}
                    {(userType === "user" ||
                        userType === "administrator" ||
                        userType === "lightuser") &&
                        companyActionAccess &&
                        (collapsed ? (
                            <Menu className={menuItemClass}>{actionsTreeData}</Menu>
                        ) : (
                            <TreeComponent
                                treeData={actionsTreeData}
                                onSelect={onSelect}
                                collapsed={collapsed}
                            />
                        ))}
                    {(userType === "user" || userType === "administrator") &&
                        hasKpiAccess &&
                        sharedDashboards.length > 0 &&
                        (collapsed ? (
                            <Menu className={menuItemClass}>{sharedAnalyticsTreeData}</Menu>
                        ) : (
                            <TreeComponent
                                treeData={sharedAnalyticsTreeData}
                                onSelect={onSelect}
                                collapsed={collapsed}
                            />
                        ))}
                    {(userType === "user" || userType === "administrator") &&
                        hasReportingAccess &&
                        companyReportingAccess &&
                        reports.length > 0 && (
                            <SubMenu
                                onTitleClick={(event: any) =>
                                    handleOnTitleClick("/Reporting", "reporting")
                                }
                                className={`${
                                    activeKey === "reporting" ? buttonActiveStyle : menuItemClass
                                }`}
                                key={"reporting"}
                                title={
                                    <div className={classes.buttonTitle}>
                                        <Link to="/Reporting">
                                            <PieChart02
                                                style={{ fontSize: 24, marginRight: iconMargin }}
                                            />
                                            {!collapsed && t("topbar_Reporting")}
                                        </Link>
                                    </div>
                                }></SubMenu>
                        )}
                    <Divider />
                    {userType === "administrator" && hasAdminAccess && (
                        <SubMenu
                            popupClassName={menuItemClass}
                            onTitleMouseEnter={() => {
                                setTimeout(() => {
                                    let elements = document.getElementsByClassName(
                                        "ant-menu-inline-collapsed"
                                    );
                                    if (elements) {
                                        if (elements[1]) {
                                            elements[1].classList.remove(
                                                "ant-menu-inline-collapsed"
                                            );
                                        }
                                    }
                                    let elements1 = document.getElementsByClassName(
                                        "ant-menu-inline-collapsed"
                                    );
                                    if (elements1) {
                                        if (elements1[1]) {
                                            elements1[1].classList.remove(
                                                "ant-menu-inline-collapsed"
                                            );
                                        }
                                    }
                                }, 150);
                            }}
                            key={"10"}
                            title={
                                <Link to="#">
                                    <SettingFilled style={{ fontSize: 24, marginRight: 14 }} />
                                    {t("admin_Settings")}
                                </Link>
                            }>
                            {!isGuest && (
                                <AdminMenu
                                    selectedKeys={selectedKey}
                                    activeKeys={activeKey}
                                    // onClick={(event: any) => handleOnSelectChange(event.key)}
                                />
                            )}
                        </SubMenu>
                    )}
                </Menu>
            ) : (
                <div id="guestMenu"></div>
            )}
        </>
    );
};

export { MainMenu };
