import React from "react";
import Icon from "@ant-design/icons";
import { GetProps } from "antd";
import classNames from "classnames";
import classes from "./styles/customIcon.module.css";
type CustomIconComponentProps = GetProps<typeof Icon>;

function PieChart02(props: Partial<GetProps<typeof Icon>>) {
    const svg = () => (
        <svg
            className={classNames(classes.customIconDefaultStyle, props.className)}
            style={{ height: props.style?.fontSize, width: props.style?.fontSize }}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.2 13.9999C17.477 13.9999 17.6155 13.9999 17.7278 14.0614C17.8204 14.1122 17.9065 14.2074 17.9478 14.3046C17.9978 14.4225 17.9852 14.5479 17.96 14.7986C17.8296 16.0987 17.3822 17.3514 16.6518 18.4445C15.7727 19.7601 14.5233 20.7855 13.0615 21.391C11.5997 21.9965 9.99113 22.1549 8.43928 21.8462C6.88743 21.5375 5.46197 20.7756 4.34315 19.6568C3.22433 18.538 2.4624 17.1125 2.15372 15.5606C1.84504 14.0088 2.00347 12.4003 2.60897 10.9385C3.21447 9.47665 4.23985 8.22722 5.55544 7.34817C6.64856 6.61777 7.90125 6.17033 9.20131 6.03989C9.45207 6.01473 9.57745 6.00215 9.69528 6.05214C9.79249 6.09338 9.88776 6.17957 9.9385 6.27218C10 6.38443 10 6.52293 10 6.79993V13.1999C10 13.48 10 13.62 10.0545 13.7269C10.1024 13.821 10.1789 13.8975 10.273 13.9454C10.38 13.9999 10.52 13.9999 10.8 13.9999H17.2Z"
                style={{ stroke: props.style?.color }}
            />
            <path
                d="M14 2.79993C14 2.52292 14 2.38442 14.0615 2.27217C14.1122 2.17957 14.2075 2.09338 14.3047 2.05213C14.4225 2.00214 14.5479 2.01472 14.7987 2.03987C16.6271 2.22327 18.346 3.03223 19.6569 4.34307C20.9677 5.65391 21.7767 7.37283 21.9601 9.20123C21.9852 9.452 21.9978 9.57738 21.9478 9.69521C21.9066 9.79242 21.8204 9.88768 21.7278 9.93842C21.6155 9.99992 21.477 9.99992 21.2 9.99992L14.8 9.99993C14.52 9.99993 14.38 9.99993 14.273 9.94543C14.1789 9.89749 14.1024 9.821 14.0545 9.72692C14 9.61997 14 9.47995 14 9.19993V2.79993Z"
                style={{ stroke: props.style?.color }}
            />
        </svg>
    );

    const PieChart02Icon = (props: Partial<CustomIconComponentProps>) => (
        <Icon component={svg} {...props} />
    );
    return <PieChart02Icon {...props} />;
}

export default PieChart02;
